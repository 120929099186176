* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  overflow-x: hidden;
}

.App {
  background: #202020;
  background: -webkit-linear-gradient(289deg, #202020 0%, #202020 50%, #4a2424 100%);
  background: linear-gradient(290deg, #202020 0%, #202020 50%, #4a2424 100%);
  height: 100vh;
  width: 100vw;
}

.blur {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.toggleBlur {
  filter: blur(5px);
  pointer-events: none;
}

.not-visible {
  visibility: hidden;
  opacity: 0;
}
@media only screen and (max-width: 480px) {
  .not-visible {
    visibility: visible;
    opacity: 1;
  }
}

.nav-logo {
  width: 222px;
  position: relative;
}
@media only screen and (max-width: 260px) {
  .nav-logo {
    width: 150px;
  }
}

nav {
  padding: 10px 90px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: all 0.3s;
}
@media only screen and (max-width: 728px) {
  nav {
    padding: 10px 45px;
  }
}
@media only screen and (max-width: 382px) {
  nav {
    padding: 10px 15px;
  }
}

.close-wrapper {
  margin-left: auto;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  position: relative;
  z-index: 11;
  pointer-events: all;
  backdrop-filter: none;
  filter: none;
}
.close-wrapper div {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.855);
  height: 3px;
  border-radius: 2px;
}

.main-btn-section {
  height: 100vh;
}

.hide,
.rotate-first,
.rotate-scnd,
.clear,
.toggleBlur {
  transition: all 0.4s;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.rotate-first {
  transform: rotate(-45deg) translate(-7px, 10px);
}

.rotate-scnd {
  transform: rotate(45deg) translate(-4px, -7px);
}

.clear {
  transform: rotate(0);
  opacity: 1;
  visibility: visible;
  filter: blur(0);
}

.pulse-animation,
.pulse-animation-scnd {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 50px;
  width: 150px;
  height: 150px;
  position: relative;
  z-index: 2;
  background-image: url("../static/images/main_logo.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  cursor: pointer;
}
.pulse-animation::before, .pulse-animation::after,
.pulse-animation-scnd::before,
.pulse-animation-scnd::after {
  content: "";
  position: absolute;
  border: 80px solid #fff;
  top: -50px;
  left: -50px;
  bottom: -50px;
  right: -50px;
  animation: bloom1 2.9s linear infinite;
  opacity: 0;
  z-index: 1;
  clip-path: polygon(50% 0%, 90% 25%, 90% 75%, 50% 100%, 10% 73%, 11% 25%);
}
@media only screen and (max-width: 728px) {
  .pulse-animation::before, .pulse-animation::after,
  .pulse-animation-scnd::before,
  .pulse-animation-scnd::after {
    border: 60px solid #fff;
  }
}
@media only screen and (max-width: 260px) {
  .pulse-animation::before, .pulse-animation::after,
  .pulse-animation-scnd::before,
  .pulse-animation-scnd::after {
    border: 55px solid #fff;
  }
}
.pulse-animation::after,
.pulse-animation-scnd::after {
  animation-delay: 0.9s;
}
@media only screen and (max-width: 728px) {
  .pulse-animation,
  .pulse-animation-scnd {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (max-width: 260px) {
  .pulse-animation,
  .pulse-animation-scnd {
    width: 60px;
    height: 60px;
  }
}

.pulse-animation-scnd {
  background-image: none;
  width: 1px;
  height: 1px;
  cursor: default;
}
.pulse-animation-scnd::before, .pulse-animation-scnd::after {
  border: 15px solid #fff;
  top: -29px;
  left: -15px;
  right: 0;
  bottom: 0;
}

.view-files-scroll {
  margin: 0;
  font-size: 13px;
}

.view-files-line {
  height: 30px;
  width: 1px;
  background-color: #e1e1e1;
  margin: 10px auto 0 auto;
}

@keyframes bloom1 {
  0% {
    transform: scale(0.5);
  }
  50% {
    opacity: 0.15;
  }
  100% {
    transform: scale(0.9);
  }
}
.logo-tooltip {
  height: 62px;
  width: 100%;
  max-width: 160px;
  background-color: #fff;
  margin: 0 auto 20px auto;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: bounce-2;
  animation-timing-function: ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-tooltip p {
  text-align: center;
  font-size: 13px;
  margin: 0;
  z-index: 9999;
  font-weight: 500;
  width: 65%;
  color: rgba(0, 0, 0, 0.85);
  position: relative;
}
.logo-tooltip p span {
  position: absolute;
  width: 100%;
  top: -20px;
  left: 0;
  transition: opacity 0.8s;
}
@media only screen and (max-width: 260px) {
  .logo-tooltip {
    height: 52px;
    max-width: 130px;
  }
}

.tooltip-arrow {
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 50px solid #fff;
  transform: rotate(90deg);
  position: absolute;
  top: 53px;
  right: 0;
  left: 0;
}
@media only screen and (max-width: 260px) {
  .tooltip-arrow {
    top: 27px;
  }
}

.pulse-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  animation: showPulseContainer 0.5s;
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes showPulseContainer {
  0% {
    opacity: 0.2;
    transform: scale(0.8) translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
}
.text-switcher__text {
  position: absolute;
  bottom: -17px;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.text-switcher__text.active {
  opacity: 1;
}

.folder-input,
.file-input {
  display: none;
}

.forms-container {
  height: 450px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 1;
  animation: showOpacity 0.6s;
  display: flex;
  width: 100%;
  max-width: 670px;
  justify-content: center;
  z-index: 2;
}
@media only screen and (max-height: 740px) {
  .forms-container {
    height: 50%;
  }
}
@media only screen and (max-width: 720px) {
  .forms-container {
    flex-direction: column;
  }
}

@keyframes showOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.form-left,
.form-right {
  background-color: #fff;
  border-radius: 5px;
  opacity: 1;
  transition: transform 0.4s, opacity 0.8s;
}
@media only screen and (max-width: 720px) {
  .form-left,
  .form-right {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.form-left {
  width: 100%;
  max-width: 250px;
  padding: 15px;
}
@media only screen and (max-width: 720px) {
  .form-left {
    margin-bottom: 20px;
  }
}

.form-right {
  margin-left: 20px;
}
@media only screen and (max-width: 720px) {
  .form-right {
    margin: 0;
  }
}

.form-top-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.43);
  padding-bottom: 10px;
}
.form-top-row img {
  margin-left: auto;
  width: 22px;
  cursor: pointer;
  transition: all 0.2s;
}
.form-top-row img:hover {
  transform: rotate(90deg);
}
.form-top-row span {
  letter-spacing: 1px;
}

.num-of-files {
  font-weight: 600;
  margin-right: 10px;
  font-size: 17px;
}

.files-size {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.71);
  margin-top: 3px;
}

.files-wrapper {
  overflow-y: auto;
  height: 93%;
  padding-right: 5px;
}

.tooltip-plus-btn {
  margin-left: auto;
}

.form-tooltip,
.transfer-tooltip {
  background-color: #f0f0f0 !important;
  border: 1px solid rgba(0, 0, 0, 0.308);
  opacity: 1 !important;
  width: 100% !important;
  max-width: 240px;
  padding: 0 !important;
  left: 5px !important;
  top: 50px !important;
}

.transfer-tooltip {
  top: 350px !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important;
}

.tooltip-content,
.transfer-tooltip-content {
  display: flex;
  flex-direction: column;
}
.tooltip-content div,
.transfer-tooltip-content div {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.3s;
  cursor: pointer;
}
.tooltip-content div span,
.transfer-tooltip-content div span {
  color: #000;
}
.tooltip-content div:hover,
.transfer-tooltip-content div:hover {
  background-color: rgb(255, 255, 255);
}
.tooltip-content div:nth-child(1),
.transfer-tooltip-content div:nth-child(1) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.301);
}

.transfer-tooltip-content a {
  text-decoration: none;
}

.tooltip-plus {
  margin: 0 10px 3px 0;
  font-weight: 500;
}

.loading-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 300px;
  background-color: rgba(255, 255, 255, 0.801);
  border-radius: 5px;
  padding: 20px 5px;
  text-align: center;
  height: 400px;
  animation: fadeIn 0.5s;
}
.loading-box img {
  margin: 0 auto;
  width: 75px;
  display: block;
}
.loading-box h5 {
  margin-top: 40px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}
.finished-part {
  margin-top: 40px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 0 10px;
}
.finished-part a {
  color: #000;
}

.view-transfer-btn {
  background-color: #f43334;
  width: fit-content;
  padding: 0 0 0 20px;
  margin: 40px auto 0 auto;
  display: flex;
  border-radius: 5px;
  height: 35px;
  align-items: center;
}
.view-transfer-btn img {
  display: inline;
  width: 30px;
  padding: 10px 7px;
  cursor: pointer;
  border-left: 1px solid #fdd6d6;
  height: 100%;
}
.view-transfer-btn a {
  color: #fdd6d6;
  text-decoration: none;
  margin-right: 20px;
}

.Toastify {
  z-index: 12;
}

.message {
  z-index: 12;
}

.contacts-mails-wrapper p {
  margin: 0;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.275);
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.187);
  display: flex;
}
.contacts-mails-wrapper p img {
  margin-left: auto;
  width: 7px;
  cursor: pointer;
}

.require-email {
  font-size: 14px;
}

@media only screen and (max-width: 480px) {
  .tooltip-part:nth-child(2) {
    display: none;
  }
}

.file-row {
  display: flex;
  width: 100%;
  padding: 10px 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.43);
  transition: background-color 0.2s;
}
.file-row:hover {
  background-color: rgb(223, 222, 222);
}
.file-row p {
  margin: 0;
}
.file-row .x-icon {
  margin: 0 6px 0 auto;
  cursor: pointer;
  width: 10px;
  transition: transform 0.2s;
}
.file-row .x-icon:hover {
  transform: scale(1.14);
}
.file-row div p {
  color: rgba(0, 0, 0, 0.9);
}
.file-row div p:nth-child(2) {
  font-size: 12px;
}
.file-row .file-icon {
  width: 12px;
  align-self: flex-start;
  margin-top: 5px;
  margin-right: 10px;
}
.file-row .folder-icon {
  width: 15px;
  margin-right: 7px;
}

.file-name {
  font-size: 15px;
}

.file-size {
  font-size: 11px !important;
}

.right-form-top-row {
  display: flex;
  padding: 13px 0 0 0;
}
.right-form-top-row p {
  width: 50%;
  text-align: center;
  margin: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 13px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.right-form-top-row .active-p {
  border-bottom: 2px solid rgba(0, 0, 0, 0.8);
  font-weight: 600;
  color: #000;
}

.form-right {
  width: 400px;
  padding-bottom: 50px;
}
.form-right form {
  width: 85%;
  margin: 25px auto 0 auto;
}
.form-right input {
  width: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.43);
  margin-bottom: 15px;
  outline: none;
  font-size: 14px;
  padding: 5px;
  transition: all 0.3s;
}
.form-right input:focus {
  outline: none;
  background-color: rgb(223, 222, 222);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.form-right textarea {
  width: 100%;
  resize: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding: 5px;
  outline: none;
  height: 160px;
}
.form-right textarea:focus {
  outline: none;
  background-color: rgb(223, 222, 222);
  border-radius: 5px;
}
.form-right .expiration-input:focus {
  background-color: transparent;
}
@media only screen and (max-height: 630px) {
  .form-right {
    overflow-y: auto;
  }
}

.right-form-bottom-row {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 12px 15px 12px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.43);
  z-index: 1;
  position: absolute;
  width: 59.6%;
  bottom: 0;
  background-color: #d2d2d2;
}
.right-form-bottom-row .settings-x-icon {
  width: 16px;
  margin-left: 5px;
}
.right-form-bottom-row img {
  width: 26px;
  cursor: pointer;
  margin-right: 10px;
}
.right-form-bottom-row div p {
  margin: 0;
  font-size: 12px;
}

.submit-form-btn,
.continue-btn,
.download-btn {
  margin-left: auto;
  text-decoration: none;
  background-color: #f43334;
  color: #fff;
  padding: 5px 25px;
  border-radius: 5px;
  transition: all 0.2s;
}
.submit-form-btn:hover,
.continue-btn:hover,
.download-btn:hover {
  background-color: #db2d2e;
  transform: scale(1.02);
}

.continue-btn {
  margin: 0 auto;
  display: block;
  width: fit-content;
  margin-top: 17px;
}

.settings-form {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  transform: scaleY(0);
  transition: all 0.16s;
}
.settings-form h6 {
  font-weight: 600;
  font-size: 17px;
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.43);
}
.settings-form .form-row {
  padding: 5px 15px;
  display: flex;
  align-items: center;
}
.settings-form .form-row p {
  margin: 0;
}
.settings-form .form-row input {
  width: 27px;
  text-align: center;
  border-bottom: none;
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
}
.settings-form .form-row .settings-password {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  width: 84%;
  height: 100%;
  color: rgba(0, 0, 0, 0.75);
}
.settings-form .form-row .settings-password:focus {
  background-color: transparent;
}
.settings-form .form-row .settings-checkbox {
  accent-color: #f43334;
  margin-left: auto;
}

.settings-pass-wrapper {
  background-color: rgba(241, 241, 241, 0.603);
  margin-left: auto;
  width: 100%;
  max-width: 150px;
  height: 35px;
  border-radius: 5px;
  padding: 5px;
  position: relative;
}
.settings-pass-wrapper img {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px !important;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.days-num-wrapper {
  margin-left: auto;
  display: flex;
  width: 100%;
  max-width: 100px;
  justify-content: space-evenly;
  background-color: rgba(241, 241, 241, 0.603);
  height: 35px;
  border-radius: 5px;
  align-items: center;
}
.days-num-wrapper .days-num-separator {
  width: 1px;
  height: 100%;
  background-color: #d4d2d2;
}
.days-num-wrapper p {
  font-size: 14px;
  letter-spacing: 1px;
  pointer-events: none;
}

.settings-line-separator {
  height: 1px;
  width: 92%;
  background-color: rgba(0, 0, 0, 0.43);
  margin: 0.5rem auto;
}

.from-input {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.43);
  padding-left: 5px;
  margin-bottom: 15px;
  transition: all 0.3s;
}
.from-input span {
  font-size: 14px;
  color: #7f7f7f;
}
.from-input input {
  border-bottom: hidden;
  background-color: transparent;
  margin: 0;
}
.from-input input:focus {
  background-color: transparent;
}
.from-input:focus-within {
  background-color: white;
  background-color: rgb(223, 222, 222);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.link-row {
  display: flex;
  margin-top: 10px;
}
.link-row input {
  margin-left: auto;
  accent-color: #f43334;
  width: 15px;
  margin-right: 3px;
}

.expiration-error,
.password-error {
  color: #ff0000;
  font-size: 13px;
}

.form-input-wrapper,
.from-input {
  position: relative;
}
.form-input-wrapper input,
.from-input input {
  padding-right: 20px;
}
.form-input-wrapper img,
.from-input img {
  position: absolute;
  top: 8px;
  right: 3px;
  width: 14px;
  cursor: pointer;
}

.form-input-tooltip,
.form-input-tooltip-from,
.form-input-tooltip-email,
.form-input-tooltip-sender {
  right: -210px !important;
  top: -30px !important;
  left: auto !important;
  background-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.848) !important;
  opacity: 1 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 13px !important;
  width: 200px !important;
}

.form-input-tooltip-from {
  width: 230px !important;
  right: -240px !important;
}

.form-input-tooltip-email {
  top: -50px !important;
}

.form-input-tooltip-sender {
  top: -43px !important;
}

.email-input-wrapper {
  position: relative;
}
.email-input-wrapper span {
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.files-wrapper::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

.files-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.files-wrapper::-webkit-scrollbar-thumb {
  background: #25282e;
  border-radius: 10px;
}

.files-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #3d4045;
}

.files-wrapper::-webkit-scrollbar {
  width: 5px;
}

.files-wrapper::-webkit-scrollbar-thumb {
  background: #989898;
}

.files-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #868686;
}

.transfering-section {
  height: 100vh;
  transition: background-color 0.4s;
}

.password-container {
  background-color: #d4d3d3;
  width: 200px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  height: 400px;
  border-radius: 5px;
  width: 300px;
  padding: 20px 0;
  animation: showPasswordContainer 0.3s;
  display: none;
  transform: translateY(-50%);
}
.password-container img {
  margin: 0 auto;
  display: block;
  width: 75px;
}
.password-container h5 {
  margin-top: 40px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
}
.password-container p {
  text-align: center;
  font-size: 14px;
  margin: 20px 0 40px 0;
  letter-spacing: 1px;
}
.password-container .settings-pass-wrapper {
  margin: 0 auto;
  width: 80%;
  max-width: unset;
}
.password-container .settings-pass-wrapper input {
  border: none;
  background-color: transparent;
  width: 90%;
  outline: none;
  font-size: 13px;
}
.password-container .settings-pass-wrapper input:focus {
  outline: none;
}
.password-container .line {
  width: 100%;
  height: 1px;
  background-color: #777777;
  margin-top: 40px;
}

@keyframes showPasswordContainer {
  0% {
    opacity: 0.5;
    transform: translateY(-60%) scale(0.7);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}
.download-pulse-container {
  position: fixed;
  transition: all 0.4s;
}
.download-pulse-container .logo-tooltip {
  display: block;
  height: 72px;
  padding: 10px 5px;
}
.download-pulse-container .logo-tooltip p:nth-child(1) {
  font-weight: bold;
}
.download-pulse-container .logo-tooltip p:nth-child(2) {
  opacity: 0.7;
}
.download-pulse-container .logo-tooltip p {
  width: 100%;
}
.download-pulse-container .logo-tooltip .tooltip-arrow {
  top: 62px;
}

.download-part {
  background-color: #d8d3d3;
  position: relative;
}
.download-part .row {
  padding: 10px;
}

.file-container {
  background-color: #fff;
  width: 300px;
  border-radius: 5px;
  padding: 70px 0 0 0;
  cursor: pointer;
  margin: 0 auto;
}
.file-container img {
  margin: 0 auto;
  display: block;
}
.file-container .line {
  width: 100%;
  height: 1px;
  background-color: #919191;
  margin-top: 70px;
}
.file-container:hover .file-bottom-part {
  background-color: #ededed;
}

.file-bottom-part {
  display: flex;
  padding: 15px 30px;
}
.file-bottom-part input {
  margin-left: auto;
  width: 17px;
}
.file-bottom-part div p {
  margin: 0;
}
.file-bottom-part div p:nth-child(1) {
  font-weight: bold;
}
.file-bottom-part div p:nth-child(2) {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.642);
}

.bottom-download-part {
  position: sticky;
  bottom: 0;
  background-color: #d8d3d3;
  border-top: 1px solid #fff;
  padding: 20px 60px;
  display: flex;
  align-items: center;
}
.bottom-download-part p {
  margin: 0;
}
.bottom-download-part p span:nth-child(1) {
  font-weight: bold;
  font-size: 18px;
}
.bottom-download-part p span:nth-child(2) {
  font-size: 14px;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.694);
}

.download-files-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 40px 40px 40px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.download-files-wrapper .loader {
  width: 70px;
  height: 70px;
  border: 5px solid #fff;
  border-bottom-color: #4a2424;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 60px 0 20px 0;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.file-top-wrapper {
  width: 550px;
  margin-top: 40px;
}

.selected-file {
  background-color: #ededed;
}
.selected-file input {
  accent-color: #f43334;
}

.view-files-container {
  position: absolute;
  bottom: 0;
  color: #e1e1e1;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
}

.progress-bar-rect-wrap-container {
  flex-direction: column;
}

.countdown-wrapper {
  position: relative;
  text-align: center;
  line-height: 1;
  top: 50%;
}
.countdown-wrapper span {
  font-size: 12px;
}

.tooltip-part {
  position: absolute;
  z-index: 9999;
  font-size: 12px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: fit-content;
  transition: opacity 0.3s;
}
@media only screen and (max-width: 260px) {
  .tooltip-part {
    font-size: 10px;
  }
}

.files-size-tooltip {
  top: 50%;
}

.progress-bar {
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.bar {
  height: 100%;
  background-color: #f43334;
}

.percentage-num {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
